export const LoginMessages = {
    title: (
      <div>
        Login
      </div>
    ),
    description:
    "Já é membro? Faça login para começar."
  
  };
  