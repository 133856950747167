import Instagram from "../assets/icons/Instagram.svg";
import Whatsapp from "../assets/icons/Whatsapp.svg";

export const HomeMessages = {
  title: (
    <div>
      Venha fazer o seu <strong>Projeto de Pesquisa Cientifica</strong> conosco!
    </div>
  ),
  description:
  "Plataforma tecnológica de educação científica exclusiva para mulheres. Tratam-se de processos e técnicas para o desenvolvimento de competências na elaboração projetos de pesquisa de impacto para ingresso em pós-graduação, melhorando as perspectivas de empregabilidade."

};

export const FaqMessages = [
  {
    question:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit? "
  },
  {
    question:
      "Sed do eiusmod tempor incididunt ut labore et dolore?"
  },
  {
    question:
      "Magna aliqua. Ut enim ad minim veniam, quis nostrud exercitat. "
  },
  {
    question:
      "Mullamco laboris nisi ut aliquip ex ea commodo consequat? "
  },
  
];

export const ContactDB = [
  {
    icon: (
      <img src= { Instagram } alt="Instagram"/>
    ),
    content:
      "@REDEFEMTECH"
  },
  {
    icon: (
      <img src= { Whatsapp } alt="Whatsapp"/>
    ),
    content:
      "(85) 99216-3000"
  },



]